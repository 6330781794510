import React from "react";
import Section from "../section";

import { GoCode } from "react-icons/go";
import styled from "styled-components";
import WorkViewer from "../slide";
import { about } from "../../data/data";
const Ul = styled.ul`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default function About() {
  return (
    <Section name="About Me" id="about">
      <div className="section-about">
        <div
          style={{ margin: " 0px 20px 20px 0px" }}
          data-aos-duration="1400"
          data-aos="fade-up"
          data-aos-once
        >
          <img src={about.image} alt="an old car" className="img-animation" />
        </div>
        <div data-aos-duration="1400" data-aos="fade-up">
          <p style={{ color: "#B2BEE3", fontSize: "18px" }}>{about.text_1}</p>
          <p style={{ color: "#B2BEE3", fontSize: "18px" }}>{about.text_2}</p>
          <p style={{ color: "#B2BEE3", fontSize: "18px" }}>{about.text_3}</p>
          <Ul style={{ color: "#B2BEE3", fontSize: "18px" }}>
            {about.tags.map((tag) => (
              <li>
                <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
                {tag}
              </li>
            ))}
          </Ul>
        </div>
      </div>
    </Section>
  );
}
