import React from "react";
import Section from "../section";
import FeaturedProject from "./projects/featuredProject";
import styled from "styled-components";
import { articles } from "../../data/data";

// import Project from "./projects/project";
// import editorCode from "../../asserts/images/editor-code.png";
// import sovence from "../../asserts/images/sovence.png";

export default function Articles() {
  return (
    <Section name="Articles" id="articles">
      <ProjectGrid>
        {articles.map((item, index) => (
          <FeaturedProject
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            img={item.img}
            external={item.external}
            github={item.github}
            titleLink={true}
          />
        ))}
      </ProjectGrid>
      {/* <h2 style={{ color: "white", textAlign: "center", margin: "100px" }}>
        Other project
      </h2>
      <RowProjects>
        {projectsList.map((item, index) => (
          <Project
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            external={item.external}
            github={item.github}
          />
        ))}
      </RowProjects> */}
    </Section>
  );
}
const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;
