import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { WebSiteContext } from "../app.v2";
import WorkViewer from "./slide";
import { MdClose } from "react-icons/md";
// Animation for modal opening
const modalOpen = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// Modal background overlay with blur effect
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: opacity ${modalOpen} 0.3s;
  z-index: 1000;
`;

// Modal box styling
const ModalBox = styled.div`
  background: #112240;
  color: #bcc8ed;
  padding: 20px;
  border-radius: 8px;
  width: 1000px;
  max-width: 100%;
  height: 90vh;
  overflow-y: auto; /* Enables vertical scrolling */
  animation: ${modalOpen} 0.3s ease-out;
`;

// Close button for the modal
const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: #62ecd0;
  border: none;
  border-radius: 100%;
  padding: 4px;
  color: black;
  z-index: 199;
  cursor: pointer;
`;

export default function Modal() {
  const {
    state: {
      modal: {
        isOpen,
        data: { images, title, describition, tags },
      },
    },
    dispatch,
  } = React.useContext(WebSiteContext);

  // Function to toggle modal visibility
  const toggleModal = () => {
    dispatch({ type: "OPEN_MODAL", data: {} });
  };

  // Close modal if clicked outside
  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      dispatch({ type: "CLOSE_MODAL" });
    }
  };

  return (
    <div>
      {isOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalBox>
            <CloseButton onClick={closeModal}>
              <MdClose size={28} />
            </CloseButton>

            <WorkViewer images={images} />

            <h2>{title}</h2>
            <p>
              {describition.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </ModalBox>
        </ModalOverlay>
      )}
    </div>
  );
}
