import editorCode from "../asserts/images/newImg/4.jpg";
import CityExplor from "../asserts/images/newImg/map 2.jpg";
import Task from "../asserts/images/newImg/task.jpg";
import Quiz from "../asserts/images/newImg/quiz.jpg";
import Music from "../asserts/images/newImg/music.jpg";
import ismail from "../asserts/images/1658937336548.jpeg";
import WaslaImg from "../asserts/images/newImg/wasla.jpg";
import Amana from "../asserts/images/newImg/smartmockups_lier7rop.jpg";
import DarnaDeco from "../asserts/images/newImg/2.jpg";
import sovence from "../asserts/images/newImg/3.jpg";
import Education from "../asserts/images/newImg/smartmockups_lwdgfaa1.jpg";
import LazyLoading from "../asserts/images/newImg/lazy-loading.jpg";

import work1 from "../asserts/images/work1.png";
import work2 from "../asserts/images/work2.png";
import work3 from "../asserts/images/work3.png";
import work4 from "../asserts/images/work4.png";

import pwork1 from "../asserts/images/work1.jpeg";
import pwork2 from "../asserts/images/work2.jpg";
import pwork3 from "../asserts/images/work3.jpg";
import pwork4 from "../asserts/images/work5.jpg";
import pwork6 from "../asserts/images/work6.jpg";
import pwork7 from "../asserts/images/work7.jpg";
import pwork8 from "../asserts/images/work8.jpg";
import pworkPub from "../asserts/images/work5.jpeg";

import pwork01 from "../asserts/images/work1.jpeg";
import pwork02 from "../asserts/images/work2.jpeg";

export const about = {
  image: ismail,
  text_1: ` Hello! My name is Ismail Hocine and I am passionate about web
            development. My journey began in 2016 when I decided to explore the
            world of creating things on the internet.`,
  text_2: ` now i'm a full-stack Web developer with <b>+4 years</b> of
            experience in designing, maintaining, testing and building scalable
            web applications. i produce a robust web solutions by adhering to
            current methods and standards.I Worked on more than 17 projects,
            such as a Transfer money platform, an eCommerce Marketplace
            Platform, and a Delivery system. Used several modern JS frameworks
            such as React.Js and Node.js.`,
  text_3: `  Here are a few technologies I’ve been working with recently:`,
  tags: [" JavaScript", "React", " Node.Js", "TypeScript", "GraphQl", "AWS"],
};
export const articles = [
  {
    name: "How to Use Memoization to Reduce Server Requests and Load Times",
    description:
      "Learn how to optimize website performance using Memoization to Enhance web development skills for faster, efficient websites. Step-by-step examples and explanations for implementing Memoization in React applications. Reduce server requests, improve load times, and enhance user experience.",
    tech: ["React", "javascript", "Memzati"],
    external:
      "https://medium.com/@ismail.hocine.dev/how-to-use-memoization-to-reduce-server-requests-and-load-times-3afabe41e8a7",
    img: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*Rr_kvZft2U-XO-lamIiNog.png",
  },
  {
    name: "Boost React Performance with Intersection Observer API for Image Loading",
    description: `Learn how to optimize website performance using Intersection Observer API. Efficiently load images, reduce page size, and create seamless user experiences. Step-by-step examples and explanations for integrating with React. Enhance web development skills for faster, efficient websites.`,
    tech: ["React", "TypeScript", "Intersection Observer API"],
    external: "https://medium.com/p/9eca0283d91b",
    img: `https://miro.medium.com/v2/resize:fit:720/format:webp/1*DQ7raSJPH1LFU0MYgLLq9Q.png`,
  },
];

export const featuredPproject = [
  {
    name: "Sleek city explorer",
    description: `a sleek city explorer! 🏙️ With a minimalist frontend fetching data from a robust backend API, this project showcases cities worldwide. Each city card offers a glimpse into its charm, and with a simple click, a modal unveils detailed information and a dynamic map powered by OpenMapmore. Explore the world, one city at a time, with this elegant and user-friendly application!`,
    tech: ["React", "Typescript", "Express.Js", "Node.Js", "cypress"],
    external: "https://challenge-cities.netlify.app/",
    github:
      "https://github.com/hocineismail/challenge-cities?tab=readme-ov-file",
    img: CityExplor,
  },
  {
    name: "Task Master",
    description: `TaskMaster, the ultimate task management solution. Seamlessly create accounts without passwords and effortlessly access them upon return. Create tasks with customizable colors, delve into nested checklists for structured project management, and employ color-coded to-do items with emojis for clarity and efficiency. TaskMaster streamlines your workflow, making productivity effortless and intuitive.`,
    tech: [
      "Webpack 5",
      "React",
      "Redux",
      "Typescript",
      "Tests: Jest + React-library-testing",
      "PWA",
      "Offline-first strategy",
    ],
    external: "https://deployment--melodic-gumption-1c183e.netlify.app/",
    github:
      "https://github.com/hocineismail/cendas-mini-task/edit/main/README.md",
    img: Task,
  },
  {
    name: "Online editor Code",
    description: `I have built is an online code editor that allows users to write and execute JavaScript 
    and HTML code, and view the results directly 
    in the browser. The platform also allows users to 
    create new files and delete existing ones, 
    providing flexibility in managing 
    their projects. Additionally, 
    the platform can be used as a tool for 
    learning, as it allows users to experiment with 
     different code snippets and see the 
     results in real-time.`,
    tech: ["React", "Javascript"],
    external: "https://hocineismail.github.io/react-editor-js-html",
    github: "https://github.com/hocineismail/react-editor-js-html",
    img: editorCode,
  },
  {
    name: "Recorder and Music Player",
    description: `The Recorder and Music Player project is a dynamic application that combines the functionalities of a voice recorder and a music player. It provides a seamless experience for recording your own audio and enjoying your favorite music tracks. The project utilizes modern technologies, such as React and TypeScript, to deliver a reliable and user-friendly interface.`,
    tech: ["React", "Javascript"],
    external: "https://recorder-music-player.netlify.app/",
    github: "https://github.com/hocineismail/Music-player",
    img: Music,
  },
  {
    name: "Quiz Online",
    description: `The project is an online quiz for JavaScript that allows users to test their knowledge and skills on the language with a set of questions related to JavaScript, each with a designated time limit. After completing the quiz, users can view their results, including their score, and it provides a convenient and 
    interactive way to improve understanding of 
    JavaScript and track progress..`,
    tech: ["React", "Javascript"],
    external: "https://hocineismail.github.io/Javascript-Online-Quiz-/",
    github: "https://github.com/hocineismail/Javascript-Online-Quiz-",
    img: Quiz,
  },
  {
    name: "Wasla",
    description: `  The project is a mobile app that allows users to request delivery to their current location and track it in real-time. It uses GPS to determine location and provides a simple interface for placing requests and tracking deliveries.`,
    tech: [
      "React, React Native",
      "Node.js",
      "Express.js",
      "Javascript",
      "Mongodb",
    ],
    external:
      "https://play.google.com/store/apps/details?id=com.wasladelivery.wasladeliveryApp",
    img: WaslaImg,
  },
];

export const projects = [
  {
    name: "Freelance services marketplace",
    description: `Developed a secure and user-friendly platform for buying and selling digital services.`,
    tech: ["Strapi", "postgres", "React", "TypeScript", "S3", "CI/CD Pipelies"],
    fullDescription: `
            Created a comprehensive marketplace solution that empowers users to connect, collaborate, and transact with ease. Key features include:\n \n

            Service Listing and Discovery: Intuitive tools for sellers to showcase their services and buyers to find the perfect fit.\n
            Secure Payment Processing: Robust API-based payment systems for safe and reliable transactions.\n
            Escrow Protection: A secure escrow system to protect both buyers and sellers.\n
            Real-Time Communication: Efficient messaging system for direct interaction between buyers and sellers.\n
            Dispute Resolution: A streamlined process to resolve any issues or disputes.\n
    `,

    img: pworkPub,
    images: [
      pworkPub,
      pwork02,
      pwork1,
      pwork2,
      pwork3,
      pwork4,
      pwork6,
      pwork7,
      pwork8,
    ],
  },
  {
    name: "E-Learning Platform",
    description: `This project is  platform is an online learning marketplace tailored to the Algerian community, allowing users to teach and take courses across various subjects. It fosters educational growth by connecting local teachers and learners, offering accessible and high-quality content.`,
    tech: ["Strapi", "SQL", "React", "TypeScript", "S3", "CI/CD Pipelies"],
    external: "https://edu.algerspace.com",
    img: Education,
  },
  {
    name: "Sovece8.com",
    description: `This project is a student-centered platform for knowledge sharing and collaboration, 
    where students can ask and answer questions, and connect 
    with teachers for support. 
    It provides an interactive and dynamic environment for learning.`,
    tech: ["React", "Javascript", "Node.js", "express", "SQL"],
    external: "https://sovece8.com/",
    img: sovence,
  },
  {
    name: "Lazy Images Gallery",
    description: `Lazy-Images-Gallery is a dynamic image gallery that utilizes lazy loading techniques inspired by popular platforms like Unsplash and Pinterest. This project leverages the Intersection Observer API to efficiently load images as they come into the viewport, enhancing performance and user experience.`,
    tech: ["React", "TypeScript"],
    external: "https://lazy-images-gallery.netlify.app",
    github: "https://github.com/hocineismail/Lazy-Images-Gallery",
    img: LazyLoading,
  },
  {
    name: "Darna Deco",
    description: `An e-commerce website that allows users to filter products by price, size, and category. The platform features product slideshows and a user-friendly form for easy order placement. The integration of a REST API and Firebase ensures seamless functionality for a smooth user experience.`,
    tech: [
      "React",
      "Javascript",
      "Styled-components",
      "Recaptcha v3",
      "Firebase",
    ],
    external: "https://darnadeco.netlify.app",
    img: DarnaDeco,
    images: [work1, work2, work3, work4],
  },
  {
    name: "Amana Transfers",
    description: `This project is a platform that enables users to send money from anywhere in the world to their family in Algeria. It allows users to deposit funds into a virtual wallet and make transfers through various methods such as cash pick-up, bank deposit, and home delivery.`,
    tech: ["React", "Typescript", "styled-components", "firebase"],
    external: "https://amanatransfers.com/",
    img: Amana,
  },
];
