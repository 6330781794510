import React from "react";
import Section from "../section";
import FeaturedProject from "./projects/featuredProject";
import styled from "styled-components";
// import editorCode from "../../asserts/images/newImg/4.jpg";
// import CityExplor from "../../asserts/images/newImg/map 2.jpg";
// import Task from "../../asserts/images/newImg/task.jpg";
// import Quiz from "../../asserts/images/newImg/quiz.jpg";
// import Music from "../../asserts/images/newImg/music.jpg";
// import WaslaImg from "../../asserts/images/newImg/wasla.jpg";
import { featuredPproject } from "../../data/data";

export default function Projects() {
  return (
    <Section name="Other Projects">
      <ProjectGrid>
        {featuredPproject.map((item, index) => (
          <FeaturedProject
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            img={item.img}
            external={item.external}
            github={item.github}
          />
        ))}
      </ProjectGrid>
      {/* <h2 style={{ color: "white", textAlign: "center", margin: "100px" }}>
        Other project
      </h2>
      <RowProjects>
        {projectsList.map((item, index) => (
          <Project
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            external={item.external}
            github={item.github}
          />
        ))}
      </RowProjects> */}
    </Section>
  );
}
const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;
