import React from "react";
import Section from "../section";
import FeaturedProject from "./projects/featuredProject";
import styled from "styled-components";
import { projects } from "../../data/data";
// images for my projects

export default function Highlighted() {
  return (
    <Section name="Highlighted Projects" id="projects">
      <ProjectGrid>
        {projects.map((item, index) => (
          <FeaturedProject
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            img={item.img}
            external={item.external}
            github={item.github}
            images={item.images}
            fullDescription={item.fullDescription}
          />
        ))}
      </ProjectGrid>
      {/* <h2 style={{ color: "white", textAlign: "center", margin: "100px" }}>
        Other project
      </h2>
      <RowProjects>
        {projectsList.map((item, index) => (
          <Project
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            external={item.external}
            github={item.github}
          />
        ))}
      </RowProjects> */}
    </Section>
  );
}
const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;
