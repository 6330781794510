import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";

// or only core styles
// Default theme
import "@splidejs/splide/css";
import "./main.css";

export default function WorkViewer({ images }) {
  return (
    <div>
      <Splide
        aria-label="My Favorite Images"
        options={{
          perPage: 1,
          perMove: 1,
          focus: "center",
          gap: "2rem",
          padding: "3rem",
          pagination: false,
          arrows: true,
          autoplay: true,
          interval: 3000,
          speed: 800,
          easing: "ease",
          pagination: true,
        }}
        onChange={(e) => console.log(e)}
      >
        {images?.map((src) => (
          <SplideSlide style={{}}>
            <img
              src={src}
              alt="Wasla Image"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "13px",
              }}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}
